import React, { useState, useEffect } from 'react';
import { Box, styled, Typography, useTheme, FormGroup, List, ListItem } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router';

import CoachDatePicker from './CoachDatePicker';
import HDSpinner from '../../hd-ui-kit/HDSpinner';
import { HDRadio } from '../../hd-ui-kit/HDRadio';
import HDButton from '../../hd-ui-kit/HDButton';
import CoachCalenderPicker from './CoachCalenderPicker';
import Api from './../../Api';
import { reactGAEvents } from '../../commons/commonFunctions';
import { DateTime } from 'luxon';
import Link from '@mui/material/Link';

dayjs.extend(utc);

interface SelectDateTimeProps {
  availableDates: Array<any>;
  availabilityAllData: any;
  selectedCoach: any;
  nextSessionId?: number;
}

const SelectDateTime = ({ availableDates, availabilityAllData, selectedCoach, nextSessionId }: SelectDateTimeProps) => {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [currentTimeSlot, setTimeSlot] = useState<any>();
  const [availableTimeSlots, setAvailableTimeSlots] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [isScreenReader, toggleScreenReaderSelect] = useState<boolean>(false);
  const [availableDatesFilter, setavailableDates] = useState<any>();

  const [month, setMonth] = useState<any>();
  const [monthList, setMonthList] = useState<any>();
  const [day, setDay] = useState<any>();
  const [dayList, setDayList] = useState<any>();
  const [year, setYear] = useState<any>();
  const [yearList, setYearList] = useState<any>();
  const [defaultDate, setDefaultDate] = useState<any>();

  useEffect(() => {
    if (availabilityAllData) {
      const timeSlots = availabilityAllData?.[availableDates?.[0]];
      setSelectedDate(availableDates?.[0]);
      setDefaultDate(DateTime.fromISO(availableDates?.[0]));
      setAvailableTimeSlots(timeSlots);
    }
    updateDateStates();
  }, []);

  const updateDateStates = () => {
    if (availableDates) {
      const availableDate: any = [];
      availableDates?.forEach((i) => {
        if (new Date(i) >= new Date()) {
          availableDate.push(i);
        }
      });
      const yearListWithOutUniq = availableDate?.map((el: any) => ({ keyValue: `${dayjs(el).format('YYYY')}`, keyName: `${dayjs(el).format('YYYY')}` }));
      const years = yearListWithOutUniq?.filter((obj: any, index: any) => {
        return index === yearListWithOutUniq?.findIndex((o: any) => obj.keyName === o.keyName);
      });
      setavailableDates(availableDate);
      setMonthList(monthListFetch(availableDate, years?.[0]?.keyValue));
      setMonth(monthListFetch(availableDate, years?.[0]?.keyValue)?.[0]?.keyValue);
      setYearList(years);
      setYear(years?.[0]?.keyValue);
      setDayList(dayListFetch(monthListFetch(availableDate, years?.[0]?.keyValue)?.[0]?.keyValue, years?.[0]?.keyValue));
      setDay(dayListFetch(monthListFetch(availableDate, years?.[0]?.keyValue)?.[0]?.keyValue, years?.[0]?.keyValue)?.[0]?.keyValue);
    }
  };
  const dayListFetch = (monthName: any, yearSelection: any) => {
    const dayNames: any = [];
    availableDates?.forEach((el: any, index: any) => {
      if (yearSelection === dayjs(el).format('YYYY') && monthName == dayjs(el).format('MMMM')) {
        dayNames.push({ keyValue: dayjs(el).format('DD'), keyName: dayjs(el).format('DD-dddd') });
      }
    });
    return dayNames;
  };

  const monthListFetch = (data: any, yearSelection: any) => {
    const monthsNameWithOutUniq: any = [];
    data?.map((el: any, index: any) => {
      if (yearSelection === dayjs(el).format('YYYY')) {
        monthsNameWithOutUniq.push({ keyValue: dayjs(el).format('MMMM'), keyName: dayjs(el).format('MMMM') });
      }
    });
    const months = monthsNameWithOutUniq?.filter((obj: any, index: any) => {
      return index === monthsNameWithOutUniq?.findIndex((o: any) => obj.keyName === o.keyName);
    });
    return months;
  };

  const onYearChange = (target: any) => {
    setYear(target.value);
    setMonthList(monthListFetch(availableDatesFilter, target.value));
    setMonth(null);
    setDayList(null);
    setDay(null);
    setAvailableTimeSlots([]);
    setTimeSlot(null);
  };

  const onMonthChange = (target: any) => {
    setMonth(target.value);
    setDayList(dayListFetch(target.value, year));
    setDay(null);
    setAvailableTimeSlots([]);
    setTimeSlot(null);
  };

  const onDayChange = (target: any) => {
    setDay(target.value);
    const formatDate = new Date(`${year}${month}${target.value}`);
    dateSelect(formatDate);
  };

  const dateSelect = (date: any) => {
    reactGAEvents('coach-schedule-session', 'date-select');
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelectedDate(formattedDate);
    const timeSlots = availabilityAllData[formattedDate];
    setAvailableTimeSlots(timeSlots);
    setTimeSlot(null);
    setDefaultDate(undefined);
  };

  const onCalendarMonthChange = (date: any) => {
    const formattedDate = dayjs(date).format('YYYY-MM');
    const nearestDate = Object.keys(availabilityAllData).find((string) => string.startsWith(formattedDate));
    const timeSlots = availabilityAllData[nearestDate];
    setSelectedDate(nearestDate);
    setAvailableTimeSlots(timeSlots);
    setTimeSlot(null);
    setDefaultDate(undefined);
  };

  const StyledLoadingText = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '26px',
    marginLeft: '10px',
    color: isDarkTheme ? '#CCCCCC' : '#002136',
  }));

  const StyledLoadingBox = { display: 'flex', marginTop: '25px' };
  const StyledSpinnerBox = { marginTop: '5px' };

  const StyledTimelistTitle = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '28.7px',
    color: isDarkTheme ? '#FFF' : '#0B0C10',
  }));

  const StyledTimelistDescription = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    color: isDarkTheme ? '#CCCCCC' : '#334255',
  }));

  const StyledListItem = styled(ListItem)(() => ({
    display: 'block',
    padding: '0 16px',
    backgroundColor: isDarkTheme ? '#1D2F44' : '#F8F8F8',
    borderRadius: 8,
    border: '2px solid #18768C',
    marginBottom: 8,
    borderColor: isDarkTheme ? '#2CDADA' : '#18768C',
  }));

  const sxDateTimeListBox = {
    marginTop: '16px',
    marginBottom: '32px',
  };

  const StyledTypographyToggle = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: isDarkTheme ? '#2CDADA' : '#18768C',
  }));

  const sxScreenReaderToggle = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Avenir',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    color: isDarkTheme ? '#2CDADA' : '#18768C', // TODO: We can define hardcoded colors inside theme
    padding: '35px 0 16px',
    borderBottom: isDarkTheme ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgba(100, 106, 124, 0.2)',
  };

  const sxListItem = (checked: boolean) => {
    if (!checked) {
      return {
        border: '1px solid rgba(100, 106, 124, 0.1)',
        borderColor: isDarkTheme ? 'rgba(0, 33, 54, 0.6)' : 'rgba(100, 106, 124, 0.1)',
      };
    }
  };

  const setupEngagement = async () => {
    setLoading(true);
    setBtnDisable(true);
    const start = currentTimeSlot;
    reactGAEvents('coach-schedule-session', 'time-submit');

    if (nextSessionId) {
      const payload = {
        sessionId: nextSessionId,
        start,
      };

      if (start != undefined && start !== '') {
        Api.scheduleSession(payload)
          .then((response: any) => {
            if (response.success) {
              window.location.reload();
            } else {
              let link = <a href='https://heidricksupport.zendesk.com/hc/en-us' target='_blank' style={{ color: 'inherit' }}>contact support</a>;
              const errorMsg = <p>We are not finding any available sessions at this time. Please try again or {link}.</p>;
              addErrorMessage(errorMsg);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        addErrorMessage('Please select any time slot');
        setBtnDisable(false);
        setLoading(false);
      }
    } else {
      if (start) {
        const payload = {
          coach_id: Number(selectedCoach.coach_id),
          start,
          subscription_size: 25,
          //assessment_files: ['', ''],
        };
        const response = await Api.postEngagement(payload);
        if (response.success) {
          navigate('/my-coach/dashboard');
        } else {
          let link = <a href='https://heidricksupport.zendesk.com/hc/en-us' target='_blank' style={{ color: 'inherit' }}>contact support</a>;
          const errorMsg = <p>We are not finding any available sessions at this time. Please try again or {link}.</p>;
          addErrorMessage(errorMsg);
        }
      } else {
        addErrorMessage('Please select any time slot');
        setBtnDisable(false);
        setLoading(false);
      }
    }
  };

  const addErrorMessage = (msg: any) => {
    setErrorMsg(msg);
    setTimeout(function () {
      setErrorMsg('');
    }, 5000);
  };

  const coachDatePickerProps = {
    monthList: monthList,
    month: month,
    yearList: yearList,
    year: year,
    dayList: dayList,
    day: day,
    onYearChange: onYearChange,
    onMonthChange: onMonthChange,
    onDayChange,
  };

  return (
    <>
      {!isScreenReader ? (
        <Link
          sx={sxScreenReaderToggle}
          onClick={() => {
            updateDateStates();
            toggleScreenReaderSelect(!isScreenReader);
            const timeSlots = availabilityAllData?.[availableDates?.[0]];
            setSelectedDate(availableDates?.[0]);
            setAvailableTimeSlots(timeSlots);
          }}
          underline='none'
          tabIndex={0}
        >
          <StyledTypographyToggle sx={{ marginRight: '8px' }}>Click here if you use a screen reader</StyledTypographyToggle>
          <ArrowForwardIcon fontSize="small" />
        </Link>

      ) : (
        <Link
          sx={sxScreenReaderToggle}
          onClick={() => {
            toggleScreenReaderSelect(!isScreenReader);
            const timeSlots = availabilityAllData?.[availableDates?.[0]];
            setSelectedDate(availableDates?.[0]);
            setDefaultDate(DateTime.fromISO(availableDates?.[0]));
            setAvailableTimeSlots(timeSlots);
          }}
          underline='none'
          tabIndex={0}
        >
          <ArrowBackIcon fontSize="small" />
          <StyledTypographyToggle sx={{ marginLeft: '8px' }}>Back to non screen reader view</StyledTypographyToggle>
        </Link>
      )}

      {isScreenReader ? <CoachDatePicker {...coachDatePickerProps} /> : <CoachCalenderPicker availableDates={availableDates} defaultMonthDate={defaultDate} dateSelect={(date: any) => dateSelect(date)} onCalendarMonthChange={(date: any) => onCalendarMonthChange(date)} />}
      {availableTimeSlots && availableTimeSlots?.length ? (
        <>
          <StyledTimelistTitle>{dayjs(new Date(selectedDate)).utc().format('dddd, MMMM DD')}</StyledTimelistTitle>
          <StyledTimelistDescription>Availability shown in your current timezone.</StyledTimelistDescription>
          <Box sx={sxDateTimeListBox}>
            <FormGroup>
              <List>
                {availableTimeSlots?.map((tslot: any, index: number) => (
                  <StyledListItem sx={sxListItem(currentTimeSlot === index)} key={`slot-${tslot.start_time_leader_specific}`}>
                    <HDRadio
                      label={tslot['start_time_leader_specific']}
                      name={tslot['start_time_leader_specific']}
                      disabled={false}
                      showBackground={false}
                      checked={currentTimeSlot === tslot['start_time_from_cinq']}
                      value={tslot['start_time_from_cinq']}
                      hasError={false}
                      onChange={({ target }: any) => setTimeSlot(target.value)}
                      id='start-time-leader-specific'
                    />
                  </StyledListItem>
                ))}
              </List>
            </FormGroup>
          </Box>
          <Box sx={{ color: '#cc33a1', fontFamily: 'Avenir', fontSize: '16px', marginBottom: '20px' }}>{errorMsg}</Box>
          <HDButton
            disabled={btnDisable}
            endIcon={btnDisable ? <HDSpinner buttonColor={true} size={'la-sm'} /> : <ArrowForwardIcon sx={{ color: isDarkTheme ? '#18768C' : '#66FCFC' }} />}
            onClick={setupEngagement}
            text='Confirm Appointment'
            showAnimation={true}
            variant={'contained'}
          />

          {loading && (
            <Box sx={StyledLoadingBox}>
              <Box sx={StyledSpinnerBox}>
                <HDSpinner size={'la-sm'} />
              </Box>
              <StyledLoadingText>We are scheduling your session</StyledLoadingText>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SelectDateTime;
