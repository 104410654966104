import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Api from '../Api';
import ReactGA from 'react-ga4';
import { globalContext } from '../store';
import { Box, useTheme, useMediaQuery, styled } from '@mui/material';
import HDNotificationBanner from '../hd-ui-kit/HDNotificationBanner';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HDButton from '../hd-ui-kit/HDButton';

export const TimeZoneOptions = [
  { label: '(GMT -12:00) Eniwetok, Kwajalein', id: '-12:00', abbreviation: 'MHT' },
  { label: '(GMT -11:00) Midway Island, Samoa', id: '-11:00', abbreviation: 'SMST' },
  { label: '(GMT -10:00) Hawaii', id: '-10:00', abbreviation: 'HAST' },
  { label: '(GMT -9:30) Taiohae', id: '-09:50', abbreviation: 'MART' },
  { label: '(GMT -9:00) Alaska', id: '-09:00', abbreviation: 'AK' },
  { label: '(GMT -8:00) Pacific Time (US & Canada)', id: '-08:00', abbreviation: 'PT' },
  { label: '(GMT -7:00) Mountain Time (US & Canada)', id: '-07:00', abbreviation: 'MT' },
  { label: '(GMT -6:00) Central Time (US & Canada), Mexico City', id: '-06:00', abbreviation: 'CT' },
  { label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima', id: '-05:00', abbreviation: 'ET' },
  { label: '(GMT -4:30) Caracas', id: '-04:50', abbreviation: 'VST' },
  { label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz', id: '-04:00', abbreviation: 'AST' },
  { label: '(GMT -3:30) Newfoundland', id: '-03:50', abbreviation: 'NST' },
  { label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown', id: '-03:00', abbreviation: 'ART' },
  { label: '(GMT -2:00) Mid-Atlantic', id: '-02:00', abbreviation: 'MAST' },
  { label: '(GMT -1:00) Azores, Cape Verde Islands', id: '-01:00', abbreviation: 'AZOST' },
  { label: '(GMT) Western Europe Time, London, Lisbon, Casablanca', id: '+00:00', abbreviation: 'GMT' },
  { label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris', id: '+01:00', abbreviation: 'RST' },
  { label: '(GMT +2:00) Kaliningrad, South Africa', id: '+02:00', abbreviation: '' },
  { label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', id: '+03:00', abbreviation: 'ARST' },
  { label: '(GMT +3:30) Tehran', id: '+03:50', abbreviation: 'IRST' },
  { label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', id: '+04:00', abbreviation: 'ARBST' },
  { label: '(GMT +4:30) Kabul', id: '+04:50', abbreviation: 'AFT' },
  { label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', id: '+05:00', abbreviation: 'YEKT' },
  { label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi', id: '+05:50', abbreviation: 'IST' },
  { label: '(GMT +5:45) Kathmandu, Pokhara', id: '+05:75', abbreviation: 'NPT' },
  { label: '(GMT +6:00) Almaty, Dhaka, Colombo', id: '+06:00', abbreviation: 'BST' },
  { label: '(GMT +6:30) Yangon, Mandalay', id: '+06:50', abbreviation: 'MYST' },
  { label: '(GMT +7:00) Bangkok, Hanoi, Jakarta', id: '+07:00', abbreviation: 'THA' },
  { label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong', id: '+08:00', abbreviation: 'SGT' },
  { label: '(GMT +8:45) Eucla', id: '+08:75', abbreviation: 'ACWST' },
  { label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', id: '+09:00', abbreviation: 'TST' },
  { label: '(GMT +9:30) Adelaide, Darwin', id: '+09:50', abbreviation: 'CAUST' },
  { label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok', id: '+10:00', abbreviation: '' },
  { label: '(GMT +10:30) Lord Howe Island', id: '+10:50', abbreviation: '' },
  { label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia', id: '+11:00', abbreviation: 'SBT' },
  { label: '(GMT +11:30) Norfolk Island', id: '+11:50', abbreviation: '' },
  { label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', id: '+12:00', abbreviation: 'NZST' },
  { label: '(GMT +12:45) Chatham Islands', id: '+12:75', abbreviation: 'CHAST' },
  { label: '(GMT +13:00) Apia, Nukualofa', id: '+13:00', abbreviation: '' },
  { label: '(GMT +14:00) Line Islands, Tokelau', id: '+14:00', abbreviation: '' },
];

export const handleRerdiretion = (data: any, navigate: any) => {
  if (data?.step_completed == 3) {
    navigate('/create-profile/personal-details');
  } else if (data?.step_completed == 2) {
    navigate('/create-profile/compensation-details');
  } else if (data?.step_completed == 1) {
    navigate('/create-profile/experience');
  }
};

export const redirectionApi = (navigate: any) => {
  const isAuth = Api.isAuthorized();
  if (isAuth) {
    // authorized
    // check to see if user needs to complete profile
    Api.getAccount()
      .then((res) => {
        let data = res.response.data;
        handleRerdiretion(data, navigate);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const reactGAEvents = (category: string, event: string) => {
  ReactGA.event({
    category: category,
    action: event,
  });
};

export const getCapabilityScore = async (assessmentId: string, roleId: string, profileData?: any, navigate?: any) => {
  return await Api.getCapabilityScore(assessmentId, roleId)
    .then(async (res) => {
      if (res?.success) {
        if (res?.response?.data?.message) {
          return { 'message': res?.response?.data?.message };
        } else {
          let capabilityRes: any = res.response.data;
          let count = 0;
          let calPercentage = 0;
          let percentage = 0;
          let skillList: any = [];
          let skillCardDetails: any = [];
          let skillCardDetailsKeys: any = [];
          let scoreDetailList: any = [];
          const capabilityResults = res?.response?.data?.results;
          Object.keys(capabilityResults)?.forEach(async (_key: any) => {
            let skillName = (_key.slice(0, 1).toUpperCase() + _key.slice(1, _key.length)).replace(/\b(\w)/g, (s: any) => s.toUpperCase());
            let score = (Math.round(capabilityResults[_key]?.benchmark_score * 20) > Math.round(capabilityResults[_key]?.user_score * 20)) ? 0 : 1;
            count = count + capabilityResults[_key]?.drive_factor_score;
            skillCardDetails.push({
              'driveFactorName': skillName,
              'score': score,
            });
            skillCardDetailsKeys[skillName] = {
              'driveFactorName': skillName,
              'score': score,
              '_key': _key,
              'value': capabilityResults[_key]
            };
          });
          scoreDetailList = await getCapabilityScoreCardDetails(skillCardDetails, skillCardDetailsKeys, profileData, navigate);
          skillList[0] = scoreDetailList;
          calPercentage = count / Object.keys(res.response?.data?.results).length;
          calPercentage = parseFloat(calPercentage.toFixed(1));
          percentage = Math.round(calPercentage);
          capabilityRes['percentage'] = percentage.toString();
          capabilityRes['LastAssessmentDate'] = res.response?.data?.last_assessment_date;
          capabilityRes['skills'] = skillList;
          return capabilityRes;
        }
      } else {
        return 'fail';
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getImpactScore = async (roleId: string, impactAssessmentId: string, profileData?: any, navigate?: any) => {
  return await Api.getExperienceReport(roleId, impactAssessmentId)
    .then((res) => {
      if (res?.success) {
        if (res?.response?.data?.message) {
          return 'errorMessage';
        } else {
          const reportData = res.response.data.results;
          let experienceReports: any = [];
          let otherDetails: any = [];
          let locationParams: any = [];
          let coreSkillCounter = 1;
          let passedItems = 0;
          Object.keys(reportData).forEach((data: any) => {
            if (!experienceReports[reportData[data].impact_area]) {
              locationParams[reportData[data].impact_area] = {};
              locationParams[reportData[data].impact_area]['title'] = reportData[data].impact_area;
              locationParams[reportData[data].impact_area]['description'] = reportData[data].impact_description;
              locationParams[reportData[data].impact_area]['roleName'] = profileData?.businessRole?.name;
              locationParams[reportData[data].impact_area]['skills'] = [];
              experienceReports[reportData[data].impact_area] = {};
              experienceReports[reportData[data].impact_area]['id'] = coreSkillCounter;
              experienceReports[reportData[data].impact_area]['title'] = reportData[data].impact_area;
              experienceReports[reportData[data].impact_area]['skills'] = [];
              experienceReports[reportData[data].impact_area]['roleName'] = profileData?.businessRole?.name;
              experienceReports[reportData[data].impact_area]['forwardClick'] = '';
              coreSkillCounter += 1;
            }
            experienceReports[reportData[data].impact_area]['skills'].push({
              text: (data.slice(0, 1).toUpperCase() + data.slice(1, data.length)).replace(/^it/i, 'IT'),
              checked: Boolean(reportData[data].score),
              progress: reportData[data].user_score * 20,
              benchMark: reportData[data].benchmark_score * 20,
              description: reportData[data].statement_description,
              statementChecked: Boolean(reportData[data].user_score >= reportData[data].benchmark_score ? 1 : 0),
            });
            locationParams[reportData[data].impact_area]['skills'].push({
              text: (data.slice(0, 1).toUpperCase() + data.slice(1, data.length)).replace(/^it/i, 'IT'),
              checked: Boolean(reportData[data].user_score >= reportData[data].benchmark_score ? 1 : 0),
              description: reportData[data].statement_description,
              progress: reportData[data].user_score * 20,
              benchMark: reportData[data].benchmark_score * 20,
            });
            experienceReports[reportData[data].impact_area]['state'] = { skillDetails: locationParams[reportData[data].impact_area] }
            experienceReports[reportData[data].impact_area]['forwardClick'] = () => {
              reactGAEvents('my-reports-experience-overview', `impact-core-experience-${locationParams[reportData[data].impact_area]?.roleName}-${locationParams[reportData[data].impact_area]?.title}`);
              const route = '/experience/details';
              navigate(route, {
                state: { skillDetails: locationParams[reportData[data].impact_area] },
              });
            };
            passedItems += reportData[data].statement_score;
          });
          Object.keys(experienceReports).forEach((data: any) => {
            experienceReports[data]['skills'] = experienceReports[data]['skills'].sort(
              (a: any, b: any) => b['checked'] - a['checked']
            );
          });
          let percentage = (passedItems / Object.keys(reportData).length);
          percentage = Math.round(parseFloat(percentage.toFixed(1)));
          otherDetails['percentage'] = percentage.toString();
          otherDetails['LastAssessmentDate'] = res?.response?.data?.last_assessment_date;
          return { 'reportData': experienceReports, 'otherDetails': otherDetails };
        }
      } else {
        return 'fail';
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const getCapabilityScoreCardDetails = async (skillCardDetails: any, skillCardDetailsKeys: any, data: any, navigate?: any) => {
  return await Api.getSkillScoreDetails(skillCardDetails)
    .then(async (response) => {
      if (response && response.response.status === 200) {
        if (response.response.data) {
          const cardDetailsData = response.response.data.map((item: any) => {
            return {
              title: item.driveFactorName,
              description: skillCardDetailsKeys[item.driveFactorName]['value']?.description,
              youPercent: Math.round(skillCardDetailsKeys[item.driveFactorName]['value']?.user_score * 20),
              rolePercent: Math.round(skillCardDetailsKeys[item.driveFactorName]['value']?.benchmark_score * 20),
              roleName: data?.businessRole?.name,
              userDetails: data,
              cardData: item.driveFactorResponse,
              state: {
                skillDetails: {
                  title: item.driveFactorName,
                  description: skillCardDetailsKeys[item.driveFactorName]['value']?.description,
                  youPercent: Math.round(skillCardDetailsKeys[item.driveFactorName]['value']?.user_score * 20),
                  rolePercent: Math.round(skillCardDetailsKeys[item.driveFactorName]['value']?.benchmark_score * 20),
                  roleName: data?.businessRole?.name,
                  cardsData: item.driveFactorResponse,
                },
                userDetails: data
              },
              forwardClick: () => {
                reactGAEvents('my-report-leadership-skills-overview', `capabilities-target--${data?.businessRole?.name}-${skillCardDetailsKeys[item.driveFactorName]['_key']}`);
                const route = '/leadership-skills/details';
                navigate(route, {
                  state: {
                    skillDetails: {
                      title: item.driveFactorName,
                      description: skillCardDetailsKeys[item.driveFactorName]['value']?.description,
                      youPercent: Math.round(skillCardDetailsKeys[item.driveFactorName]['value']?.user_score * 20),
                      rolePercent: Math.round(skillCardDetailsKeys[item.driveFactorName]['value']?.benchmark_score * 20),
                      roleName: data?.businessRole?.name,
                      cardsData: item.driveFactorResponse,
                    },
                    userDetails: data
                  },
                });
              },
            };
          });
          return cardDetailsData;
        }
      }
      return {};
    });
};

export const LegendContent = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { globalState } = useContext(globalContext);
  const WrapperYouAndBenchmark = styled(Box)(() => ({
    display: 'flex',
    justifyContent: isMobile ? 'flex-start' : 'flex-end',
    textAlign: 'left',
    alignItems: 'flex-start',
  }));

  const InnerWrapper = styled(Box)(() => ({
    display: 'flex',
  }));

  const RoleLegendWrapper = styled(Box)(() => ({
    height: '18px',
    width: isMobile ? '22px' : '19px',
    marginTop: '2px',
  }));

  const RoleLegend = styled(Box)(() => ({
    height: '100%',
    width: '18px',
    borderRadius: '50%',
    background: (isDark) ? '#fff' : '#0b0c10',
    border: '1px solid' + ((isDark) ? '#0b0c10' : 'transparent'),
    display: 'inline-block'
  }));

  const YouLegendWrap = styled(Box)(() => ({
    display: 'flex',
    marginTop: '2px',
  }));

  const YouLengendLeft = styled(Box)(() => ({
    background: (isDark) ? '#FEC350' : '#bc7d01',
    height: '16px',
    width: '8px',
    borderRadius: '150px 0 0 150px',
    marginRight: '1px',
  }));

  const YouLengendRight = styled(Box)(() => ({
    background: (isDark) ? '#2CDADA' : '#18768c',
    height: '16px',
    width: '8px',
    borderRadius: '0 150px 150px 0'
  }));

  const TypographyStyledYou = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '5px',
    paddingRight: '13px',
    color: isDark ? '#FFFFFF' : '#002136'
  };

  const TypographyStyledBenchMark = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '5px', color: isDark ? '#FFFFFF' : '#002136'
  };

  return (
    <WrapperYouAndBenchmark>
      <InnerWrapper>
        <YouLegendWrap>
          <YouLengendLeft>&nbsp;</YouLengendLeft>
          <YouLengendRight>&nbsp;</YouLengendRight>
        </YouLegendWrap>
        <Box sx={TypographyStyledYou}>You</Box>
      </InnerWrapper>
      <InnerWrapper>
        <RoleLegendWrapper>
          <RoleLegend>&nbsp;</RoleLegend>
        </RoleLegendWrapper>
        <Box sx={TypographyStyledBenchMark}>{globalState?.profile?.businessRole?.name} target</Box>
      </InnerWrapper>
    </WrapperYouAndBenchmark>
  );
};

export const backgroundTopLeft = {
  background: 'radial-gradient(200% 70.42% at 6.02% 0%, #E3ECEF 0%, rgba(241, 241, 241, 0) 40%) #FFF',
  backgroundRepeat: 'no-repeat',
};

export const backgroundTopLeftDark = {
  background: 'radial-gradient(200% 70.42% at 6.02% 0%, #002136 0%, #002136 20.91%, rgba(7, 29, 45, 0.22) 35%, rgba(11, 12, 14, 0) 0%),#0B0C10',
  backgroundRepeat: 'no-repeat',
};


export const backgroundTopRight = {
  background: 'radial-gradient(120vh 140vh at right 0%, #E3ECEF 60%, rgba(241, 241, 241, 0) 100%) #FFF',
  backgroundRepeat: 'no-repeat',
};

export const backgroundTopRightDark = {
  background: 'radial-gradient(120vh 140vh at right 0%, #002136 60%, #002136 0%, rgba(7, 29, 45, 0.22) 100%, rgba(11, 16, 14, 0) 130%),#0B0C10',
  backgroundRepeat: 'no-repeat',
};

export const backgroundBottom = {
  background: 'radial-gradient(200% 80.42% at 30.02% 100%, #E3ECEF 0%, rgba(241, 241, 241, 0) 70%) #FFF',
  backgroundRepeat: 'no-repeat',
};

export const backgroundBottomDark = {
  background: 'radial-gradient(140.34% 70% at left bottom, #197E95 0%, #011E31 36.09%, #0B0C10 100%)',
  backgroundRepeat: 'no-repeat',
};

export const CoachingBanner = (props: any) => {
  const [sessionsList, setSessionsList] = useState({});
  const [bannerText, setBannerText] = useState('');
  const [bannerBtnText, setBannerBtnText] = useState('');
  const [isApiCall, setIsApiCall] = useState(true);
  let navigate = useNavigate();
  const { globalState } = useContext(globalContext);
  const unlockBannerText = 'Unlock your potential and accelerate your career growth with our executive coaching program.';
  const exploreBannerText = 'Explore how our executive coaching program can help you achieve your career goals.';
  const discoverBtnText = 'Discover coaching';
  const subscribeBtnText = 'Subscribe now';

  const getBannerData = async () => {
    const assessmentStatus = globalState?.assessmentStatus;
    const assessments: any = globalState?.profile?.assessments;
    let assessmentArr: any = [];
    if (assessments) {
      assessments.forEach((data: any) => {
        assessmentArr[data.name] = { ...data };
      });
    }
    let isCapabilityCompleted = Boolean(assessmentArr?.Capabilities?.status === 'C' || assessmentStatus?.cac === 'yes');
    let isImpactCompleted = Boolean(assessmentArr?.Impact?.status === 'C' || assessmentStatus?.iac === 'yes');

    const setBannerTextFunc = (bannerText: string, btnTitle: string, apiCall: boolean) => {
      setBannerText(bannerText);
      setBannerBtnText(btnTitle);
      setIsApiCall(apiCall);
    };

    if ((assessmentStatus?.cas == 'yes' || assessmentStatus?.ias == 'yes') && (!isCapabilityCompleted || !isImpactCompleted)) {
      setIsApiCall(true);
    } else if (!isCapabilityCompleted || !isImpactCompleted) {
      setBannerTextFunc(unlockBannerText, discoverBtnText, false);
    } else {
      let res: any;
      if (globalState?.sessionsData) {
        res = globalState?.sessionsData;
      }
      if (res?.success) {
        const sessionsListLocal = res?.response?.data?.sessions;
        if (sessionsListLocal && Object.keys(sessionsListLocal)?.length > 0) {
          setSessionsList(sessionsListLocal);
          if (sessionsListLocal?.[0]?.['is_completed'] || new Date(sessionsListLocal?.[0]?.['start_date_hfl_specific']?.replace(/-/g, '/')).getTime() < new Date().getTime()) {
            setBannerTextFunc(unlockBannerText, subscribeBtnText, false);
          } else {
            setIsApiCall(true);
          }
        } else if (isCapabilityCompleted && isImpactCompleted) {
          setBannerTextFunc(exploreBannerText, discoverBtnText, false);
        } else {
          setBannerTextFunc(exploreBannerText, discoverBtnText, false);
        }
      } else if (isCapabilityCompleted && isImpactCompleted) {
        setBannerTextFunc(exploreBannerText, discoverBtnText, false);
      }
    }
  };

  useEffect(() => {
    if (globalState?.subscriptionStatus?.status === 'NOT_SIGNED_UP') {
      getBannerData();
    } else {
      setIsApiCall(true);
    }
  }, [props?.isStatusUpdated, globalState?.sessionsData?.response]);

  const btnStyle = {
    background: '#F1F1F1',
    color: '#002136',
    fontFamily: 'Aventa',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#F1F1F1',
    },
    '&:focus': {
      background: '#F1F1F1 !important',
      border: 'none',
      '::before': {
        background: 'none',
      }
    },
  };

  const ArrowIcon = styled(ArrowForwardIcon)(() => ({
    color: '#18768C'
  }));

  return (
    <>
      {!isApiCall && (Object.keys(sessionsList)?.length === 0 || Object.keys(sessionsList)?.length > 1) ? (
        <>
          <HDNotificationBanner
            content={bannerText}
            variant={'filled'}
            severity={'success'}
            icon={false}
            action={
              <>
                <HDButton
                  text={bannerBtnText}
                  variant={'contained'}
                  size={'medium'}
                  showAnimation={true}
                  sx={btnStyle}
                  endIcon={<ArrowIcon />}
                  onClick={() => {
                    navigate('/my-coach');
                  }}
                />
              </>
            }
            style={{
              textAlign: 'left',
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const addGAEventsForChemistrySession = (gaTag: string) => {
  const urlList = [
    { '/home': 'home' },
    { '/experience/overview': 'experience-overview' },
    { '/experience/details': 'experience-details' },
    { '/leadership-skills/overview': 'leadership-skills-overview' },
    { '/leadership-skills/details': 'leadership-skills-details' },
    { '/my-coach': 'coach-select-coach' }
  ];
  const currentPath = window.location.pathname;
  const matchingKey = urlList.find(item => currentPath === Object.keys(item)[0]);
  const category = matchingKey ? Object.values(matchingKey)[0] : '';
  reactGAEvents(category, gaTag);
};

export const getAssesmentStatus = async () => {
  return await Api.getAssesmentStatus()
    .then((res) => {
      if (res?.success) {
        const result = res?.response?.data;
        const statusArray = {
          cas: result?.capability_assessment_started,
          cac: result?.capability_assessment_completed,
          ias: result?.impact_assessment_started,
          iac: result?.impact_assessment_completed,
          otherDetails: result,
        };
        return statusArray;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const titleStyle = (isMobile: boolean, themeMode: string) => {
  return {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: isMobile ? '31px' : '41px',
    lineHeight: isMobile ? '41px' : '54px',
    color: themeMode == 'light' ? '#002136' : '#FFFFFF',
    textAlign: 'left',
  };
};

export const styledText = (themeMode: string) => {
  return {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: themeMode == 'light' ? '#334255' : '#CCCCCC',
    textAlign: 'left',
    marginTop: '15px',
  };
};

export const styledSignInBtn = (themeMode: string) => {
  return {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: themeMode == 'light' ? '#18768C' : '#2CDADA',
    padding: '0px',
    textDecoration: 'underline',
    ':hover': {
      color: themeMode == 'light' ? '#18768C' : '#2CDADA',
    },
  };
};

export const styledBoxMainWrapper = (isMobile: boolean, themeMode: string) => {
  let background =
    `url(res/SignInBG${themeMode === 'light' ? 'Light' : 'Dark'
    }${isMobile ? 'Mobile' : ''
    }.svg) no-repeat top`;
  return {
    background: background,
    backgroundPosition: isMobile ? 'right bottom' : 'right',
    height: isMobile ? '510px' : '570px',
  };
};

export const styledDividerLeft = (isMobile: boolean, themeMode: string) => {
  return {
    borderLeft: !isMobile
      ? `1px solid rgba(${themeMode === 'light' ? '0, 33, 54, 0.2' : '255, 255, 255, 0.2'})`
      : '',
    paddingLeft: isMobile ? ' ' : '40px',
    marginTop: isMobile ? '32px' : '64px',
    width: isMobile ? '100%' : '50%',
  };
};

export const generateCoachData = (item: any, isDarkTheme: boolean) => {
  return ([
    {
      ...item,
      imgUrl: isDarkTheme ? '../res/hd-ui-kit-images/bioIconDark.svg' : '../res/hd-ui-kit-images/bioIconLight.svg',
      title: 'Bio',
      alt: 'Coach bio logo',
      des: item?.bio,
    },
    {
      ...item,
      imgUrl: isDarkTheme ? '../res/hd-ui-kit-images/expIconDark.svg' : '../res/hd-ui-kit-images/expIconLight.svg',
      title: 'Recent Coaching Experience',
      alt: 'Coaching experience logo',
      des: item?.recent_coaching_experience,
    },
    {
      ...item,
      imgUrl: isDarkTheme ? '../res/hd-ui-kit-images/qualIconDark.svg' : '../res/hd-ui-kit-images/qualIconLight.svg',
      title: 'Qualifications and Background',
      alt: 'Qualification & Background logo',
      des: [...item.qualifications, ...item.background],
    },
  ]);
};

export const exportPDF = (setShowLoader: any, reportName: string, fileName: string, gaTags: any, setApiError: any) => {
  setShowLoader(true);
  reactGAEvents(gaTags?.category, gaTags?.tagName);
  const errMessage = 'We are having trouble while downloading your reports, please try again later.';
  return Api.exportPDF(reportName)
    .then((res) => {
      if (res && res?.success) {
        setShowLoader(false);
        if (res?.response?.data?.report) {
          const linkSource = `data:application/pdf;base64,${res.response.data.report}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          setApiError(errMessage);
          setTimeout(() => {
            setApiError('');
          }, 5000);
        }

      } else {
        setShowLoader(false);
        setApiError(errMessage);
        setTimeout(() => {
          setApiError('');
        }, 5000);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const exportBtnStyle = (isMobile: boolean, themeMode: string) => {
  return {
    padding: 0,
    display: isMobile ? 'none' : 'flex',
    fontWeight: 500,
    '&:focus': {
      border: 'none'
    },
    color: themeMode == 'light' ? '#18768C' : '#66FCFC',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    }
  };
};

export const detailsBtnStyle = (themeMode: string) => {
  return {
    fontFamily: 'Avenir',
    fontWeight: 600,
    fontSize: '16px',
    padding: 0,
    textWrap: 'nowrap',
    display: 'flex',
    color: themeMode == 'light' ? '#18768C' : '#66FCFC',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  };
};

export const handleForward = (path: string, navigate: any, state: any) => {
  const route = path;//'/leadership-skills/details';
  navigate(route, {
    state
  });
};

export const GenerateFooterSocialIcons = (props: any) => {
  const StyledBoxIconsWrapper = styled(Box)(() => ({
    display: 'flex',
    width: (props?.theme) ? '100%' : '136px',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: (props?.theme) ? 'end' : 'flex-start',
    marginTop: '3px',
    flexDirection: (props?.theme) ? 'unset' : 'row',
    padding: '0px',
    gap: (props?.theme) ? 'auto' : '20px',
    height: (props?.theme) ? 'auto' : '24px',
  }));

  const styleIconDeskProps = {
    [props?.theme?.breakpoints.down('md')]: { marginRight: '4px' },
    marginRight: '29px',
  };

  let styledIconArray = {
    color: '#CCCCCC'
  };

  if (props?.theme) {
    styledIconArray = { ...styledIconArray, ...styleIconDeskProps };
  }

  const StyledLinkedInIcon = styled(props?.LinkedInIcon)(() => ({
    ...styledIconArray
  }));

  const StyledTwitterIcon = styled('img')(() => ({
    color: '#CCCCCC',
    [props?.theme?.breakpoints.down('md')]: { marginRight: '4px' },
  }));

  const StyledYouTubeIcon = styled(props?.YouTubeIcon)(() => ({
    ...styledIconArray
  }));

  return (
    <StyledBoxIconsWrapper>
      <Box>
        <a href={props?.urls?.linkedInUrl} target='_blank'>
          <StyledLinkedInIcon />
        </a>
      </Box>
      <Box>
        <a href={props?.urls?.youtubeUrl} target='_blank'>
          <StyledYouTubeIcon />
        </a>
      </Box>
      <Box>
        <a href={props?.urls?.twitterUrl} target='_blank'>
          <StyledTwitterIcon src={window.location.origin + '/res/email-template-images/twitter-logo.svg'} alt='twitter' />
        </a>
      </Box>
    </StyledBoxIconsWrapper>
  );
};

export const ResponsiveMobileValue = (isMobile: any, mobileValue: any, otherValue: any) => {
  return isMobile ? mobileValue : otherValue;
};

export const ResponsiveTabletValue = (isTablet: any, tabletValue: any, otherValue: any) => {
  return isTablet ? tabletValue : otherValue;
};

export const ResponsiveThemeValue = (theme: any, lightTheme: any, darkTheme: any) => {
  return theme.palette.mode === 'light' ? lightTheme : darkTheme;
};

export const ResponsiveMobileTabletValue = (isMobileTabletValue: any, mobileTabletValue: any, otherValue: any) => {
  return isMobileTabletValue ? mobileTabletValue : otherValue;
};

