import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Divider, Grid, Typography, Container, useMediaQuery, useTheme, CssBaseline, styled } from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import HDTextField from '../../hd-ui-kit/HDTextField';
import Api from '../../Api';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import Loader from '../../commons/Loader';
import { globalContext } from '../../store';
import { reactGAEvents, backgroundTopRight, backgroundTopRightDark, ResponsiveMobileValue, ResponsiveThemeValue } from '../../commons/commonFunctions';
import Link from '@mui/material/Link';
import HDModal from '../../hd-ui-kit/HDModal';

const SignIn = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState<boolean>(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [isLoginSessionExists, setIsLoginSessionExists] = useState<any>(false);
  const { state }: any = useLocation();
  let navigate = useNavigate();
  const { dispatch } = useContext(globalContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const fromCampaign = queryParameters.get('source');
  const isMobileOrTablet = isMobile || isTablet;
  if (fromCampaign || localStorage.getItem('fromCampaign')) localStorage.setItem('fromCampaign', JSON.stringify('True'));

  function handleForgetPassword() {
    reactGAEvents('login', 'forgot-password');
    navigate('/forget-pass-verify');
  }

  function handleRegister() {
    reactGAEvents('login', 'sign-up');
    navigate('/register');
  }

  // login function
  const handleSubmit = (event: { preventDefault: () => void }) => {
    reactGAEvents('login', 'sign-in');
    event.preventDefault();
    //Reg Ex for finding script tags
    const chkForTagsRegEx = new RegExp('.*\\<[^>]+>.*'); //NOSONAR
    //Reg Ex to validate email
    const chkForEmailRegEx = new RegExp('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'); //NOSONAR

    if (username === '') {
      setErrors({ email: 'Please enter email address' });
      return;
    } else if (chkForTagsRegEx.test(username)) {
      setErrors({ email: 'Email should not contain HTML Tags' });
      return;
    } else if (!chkForEmailRegEx.test(username)) {
      setErrors({ email: 'Please enter valid email address' });
      return;
    }

    if (password === '') {
      setErrors({ password: 'Please enter password' });//NOSONAR
      return;
    } else if (password.length > 128) {
      setErrors({ password: 'Password should not be more than 128 characters' });//NOSONAR
      return;
    } else if (chkForTagsRegEx.test(password)) {
      setErrors({ password: 'Password should not contain HTML Tags' });//NOSONAR
      return;
    }
    setLoading(true);
    Api.login({ email: username, password: password }).then((response) => {
      if (response.success) {
        if (response?.sessionExists) {
          setIsLoginSessionExists(true);
        } else {
          handleMyAccount();
        }

      } else {
        const { data }:any = response;
        setErrors(data);
        setLoading(false);
      }
    });
  };

  //Handle My account function
  const handleMyAccount = () => {
    const fromEmailSession = JSON.parse(localStorage.getItem('fromEmailSession'));
    const fromEmailMyCoach = JSON.parse(localStorage.getItem('fromEmailMyCoach'));
    const fromEmailDP = JSON.parse(localStorage.getItem('fromEmailDP'));
    Api.getAccount()
      .then((res) => {
        let data = res.response?.data;
        dispatch({ type: 'SET_ACCOUNT', payload: data });
        if (fromEmailSession) {
          setLoading(false);
          localStorage.removeItem('fromEmailSession');
          navigate('/my-coach/dashboard');
        }
        else if (fromEmailMyCoach) {
          setLoading(false);
          localStorage.removeItem('fromEmailMyCoach');
          navigate('/my-coach');
        }
        else if (fromEmailDP) {
          setLoading(false);
          navigate('/my-coach');
        }
        else if (fromCampaign || localStorage.getItem('fromCampaign')) {
          setLoading(false);
          localStorage.removeItem('fromCampaign');
          openPaymentSession(data);
        }
        else if (data?.personal_details?.first_name && data?.experience != null && data?.step_completed == 4) {
          if (state?.fromEmailPRef) {
            setLoading(false);
            navigate('/profile-edit?active=0');
          } else {
            setLoading(false);
            navigate(state?.path + state?.search || '/home');
          }
        }
        else {
          if (data?.step_completed == 3) {
            setLoading(false);
            navigate('/create-profile/personal-details');
          } else if (data?.step_completed == 2) {
            setLoading(false);
            navigate('/create-profile/compensation-details');
          } else if (data?.step_completed == 4) {
            if (state?.fromEmailPRef) {
              setLoading(false);
              navigate('/profile-edit?active=0');
            } else {
              setLoading(false);
              navigate(state?.path + state?.search || '/home');
            }
          } else {
            setLoading(false);
            navigate('/create-profile/experience');
          }
        }
      })
      .catch(() => {
        setLoading(false);
        navigate('/register');
      });
  };

  //Handle Session exists continue
  const handleSessionExistsContinue = () => {
    setIsLoginSessionExists(false);
    handleMyAccount();
  };

  //Handle Session exists logout
  const handleSessionExistsLogout = () => {
    Api.clearExistingSessions().then((res) => {
      if (res.success) {
        handleMyAccount();
      }
    });
  };

  // login with linkedIn
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scope: 'r_emailaddress r_liteprofile',
    onSuccess: (code) => {
      reactGAEvents('login', 'li-sign-in');
      const payload = {
        access_code: code,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      };
      setLoading(true);
      Api.tokenExchange(payload)
        .then((response) => {
          if (response.success) {
            Api.getAccount()
              .then((res) => {
                let data = res.response.data;
                const fromEmailSession = JSON.parse(localStorage.getItem('fromEmailSession'));
                const fromEmailMyCoach = JSON.parse(localStorage.getItem('fromEmailMyCoach'));
                const fromEmailDP = JSON.parse(localStorage.getItem('fromEmailDP'));
                dispatch({ type: 'SET_ACCOUNT', payload: data });
                if (fromEmailSession) {
                  setLoading(false);
                  localStorage.removeItem('fromEmailSession');
                  navigate('/my-coach/dashboard');
                }
                else if (fromEmailMyCoach) {
                  setLoading(false);
                  localStorage.removeItem('fromEmailMyCoach');
                  navigate('/my-coach');
                }
                else if (fromEmailDP) {
                  setLoading(false);
                  navigate('/my-coach');
                }
                else if (fromCampaign || localStorage.getItem('fromCampaign')) {
                  setLoading(false);
                  localStorage.removeItem('fromCampaign');
                  openPaymentSession(data);
                }
                else if (data?.personal_details?.first_name && data.experience != null) {
                  if (state?.fromEmailPRef) {
                    setLoading(false);
                    navigate('/profile-edit?active=0');
                  } else {
                    setLoading(false);
                    navigate(state?.path + state?.search || '/home');
                  }
                } else {
                  if (data?.step_completed == 3) {
                    setLoading(false);
                    navigate('/create-profile/personal-details');
                  } else if (data?.step_completed == 2) {
                    setLoading(false);
                    navigate('/create-profile/compensation-details');
                  } else if (data?.step_completed == 4) {
                    if (state?.fromEmailPRef) {
                      setLoading(false);
                      navigate('/profile-edit?active=0');
                    } else {
                      setLoading(false);
                      navigate(state?.path + state?.search || '/home');
                    }
                  } else {
                    setLoading(false);
                    navigate('/create-profile/experience');
                  }
                }
              })
              .catch((err) => {
                setLoading(false);
                navigate('/register');
              });
          } else {
            const { data }:any = response;
            setErrors(data);
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrors(err);
          setLoading(false);
        });
    },
    onError: (error) => {
      // Errors here are handled in the linkedIn window
    },
  });

  const openPaymentSession = async (data: any) => {
    try {
      let url = window.location.origin;
      if (data?.step_completed == 3) {
        url += '/create-profile/personal-details';
      } else if (data?.step_completed == 2) {
        url += '/create-profile/compensation-details';
      } else if (data?.step_completed == 1) {
        url += '/create-profile/experience';
      } else {
        url += '/my-coach';
      }

      const payload = {
        price_type: 'MONTHLY',
        success_url: url,
        cancel_url: url,
      };

      const response = await Api.subscribeStripe(payload);
      if (response.success) {
        const { url, already_subscribed } = response.response.data;

        if (already_subscribed === true) {
          window.open('/my-coach', '_self');
        } else {
          window.open(url, '_self');
        }
      } else {
        navigate('/home');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const TitleStyle = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: ResponsiveMobileValue(isMobile, '31px', '41px'),
    lineHeight: ResponsiveMobileValue(isMobile, '41px', '54px'),
    color: ResponsiveThemeValue(theme, '#002136', '#FFFFFF'),
    textAlign: 'left',
  };

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    textAlign: 'start',
  };

  const StyledText = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: ResponsiveThemeValue(theme, '#334255', '#CCCCCC'),
    textAlign: 'left',
    marginTop: '15px',
  };

  const StyledForgetPass = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: ResponsiveThemeValue(theme, '#18768C', '#2CDADA'),
    textAlign: 'left',
    padding: '0px',
    textDecoration: 'underline',
    cursor: 'pointer'
  };
  const TitleStyleHaveAccount = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: ResponsiveThemeValue(theme, '#002136', '#CCCCCC'),
    textAlign: 'left',
  };

  const StyledDivider = styled(Divider)(() => ({
    borderColor: theme.palette.mode == 'light' ? 'rgba(100, 106, 124, 0.2)' : 'rgba(255, 255, 255, 0.2)',
    marginTop: '20px',
    marginBottom: '16px',
  }));

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: ResponsiveThemeValue(theme, '#66FCFC', '#18768C'),
  }));

  const StyledSignIn = {
    backgroundColor: ResponsiveThemeValue(theme, '#334255', '#F1F1F1'),
    color: ResponsiveThemeValue(theme, '#FFFFFF', '#002136'),
    marginTop: '20px',
    marginBottom: '20px',
  };

  const StyledLinkedIn = {
    backgroundColor: ResponsiveThemeValue(theme, '#334255', '#F1F1F1'),
    color: ResponsiveThemeValue(theme, '#FFFFFF', '#002136'),
    width: '100%',
    marginTop: '20px',
    padding: '6px 16px',
    marginBottom: '20px',
    marginLeft: '0px',
  };

  const StyledSignUp = {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    //paddingBottom: '20px',
  };

  const StyledSignUpBtn = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: ResponsiveThemeValue(theme, '#18768C', '#66FCFC'),
    padding: '0px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '5px'
  };

  const StyledForgetWrapper = {
    textAlign: 'left',
    marginTop: '20px',
  };
  const Styledgridwrapper = {
    display: 'flex',
    alignItems: 'center',
  };

  const StyledOr = styled(Box)(() => ({
    color: theme.palette.mode == 'light' ? '#334255' : '#A9A9A9',
    fontFamily: 'Avenir',
    fontWeight: 400,
  }));

  let backgroundImage =
    `url(res/NewPasswordBG${theme.palette.mode === 'light' ? 'Light' : 'Dark'
    }${isMobileOrTablet ? 'Mobile' : ''
    }.svg)`;

  const BackImage = styled(Box)(() => ({
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    backgroundImage: backgroundImage,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: isMobileOrTablet ? 'right bottom' : 'right top',
    right: isMobileOrTablet ? 0 : '-30px',
    top: isMobileOrTablet ? 'auto' : 0,
    bottom: 0,
    backgroundSize: isMobile ? '90%' : isTablet ? '63%' : '52%',
  }));
  const ContainerStyle = {
    position: 'relative',
    paddingBottom: isMobileOrTablet ? '172px' : 0,
  };
  const ErrorStyle = {
    color: '#cc33a1 !important',
    fontFamily: 'Avenir',
    textAlign: 'start',
    fontSize: '16px',
  };

  const noteStyle = {
    fontFamily: 'Aventa',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: ResponsiveThemeValue(theme, '#002136', '#CCC'),
    marginBottom: '32px',
    marginTop: '32px',
    display: 'flex',
    alignItems: 'center',
  };

  const btnStyle = {
    fontSize: ResponsiveMobileValue(isMobile, '13px', '14px'),
    padding: 0,
    margin: 0,
    fontWeight: 400,
    textDecoration: 'underline',
    letterSpacing: '0px',
    cursor: 'pointer',
    color: ResponsiveThemeValue(theme, '#18768C', '#66FCFC'),

  };

  const boxWrapper = { display: 'flex', alignItems: 'center', marginTop: '32px' };

  const styledCardWrappers = {
    display: 'flex',
    flexDirection: 'column',
    width: !isMobile && !isTablet ? '55%' : '100%',
    borderLeft: isMobileOrTablet ? ' ' : theme.palette.mode == 'light' ? '1px solid rgba(0, 33, 54, 0.2)' : '1px solid  rgba(255, 255, 255, 0.2)',
    paddingLeft: isMobileOrTablet ? ' ' : '40px',
    //marginTop: '20px',
    marginTop: ResponsiveMobileValue(isMobile, '32px', '64px'),
    paddingBottom: ResponsiveMobileValue(isMobile, '0px', '64px'),
  };

  const btnBox = {
    marginLeft: '3px',
    marginBottom: '2px'
  };

  const StyledMainWrapper = { display: 'flex', flexWrap: 'wrap', position: 'relative' };

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) navigate('/home');
  }, []);

  const ModalAction = () => {
    const actionBtnStyle = {
      display: 'flex',
      justifyContent: 'start',
      width: '100%',
    };

    const linkStyle = {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.mode == 'light' ? '#18768C' : '#66FCFC',
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'flex',
      alignSelf: 'center',
      marginLeft: '20px'
    };

    return (
      <Box sx={actionBtnStyle}>
        <HDButton
          onClick={handleSessionExistsLogout}
          text={'Yes'}
          variant={'contained'}
          size={'medium'}
        />
        <Link underline='none' sx={linkStyle} onClick={handleSessionExistsContinue}>
          No, keep me logged in
        </Link>
      </Box>
    );
  };

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Box sx={theme.palette.mode === 'light' ? { ...backgroundTopRight } : { ...backgroundTopRightDark }}>
        <Container maxWidth="lg" sx={ContainerStyle}>
          <BackImage></BackImage>
          <Box sx={StyledMainWrapper}>
            <Box sx={styledCardWrappers}>
              {errors?.non_field_errors &&
                errors.non_field_errors.map((item: any, index: number) => (
                  <Typography variant="h2" sx={ErrorStyle} key={`error_${index}`}>
                    {item}
                  </Typography>
                ))}
              {errors?.detail == 'User account not verified.' ? (
                <Typography variant="h2" sx={ErrorStyle}>
                  {errors?.detail + ' Please check your email for instructions.'}
                </Typography>
              ) : (
                <Typography variant="h2" sx={ErrorStyle}>
                  {errors?.detail}
                </Typography>
              )}
              <Box>
                <Box sx={{ ...MainStyledBox, marginTop: '0px' }}>
                  <Typography variant="h2" sx={TitleStyle}>
                    Sign in
                  </Typography>
                  <Typography variant="h2" sx={StyledText}>
                    Welcome to Heidrick & Struggles. Log in to access your leadership profile.
                  </Typography>
                </Box>
                <Box sx={MainStyledBox}>
                  {/* <Typography variant="h2" sx={StyledEmailText}>
                  Email
                </Typography> */}
                  <HDTextField
                    onClick={() => reactGAEvents('login', 'email')}
                    type="text"
                    lblText="Email"
                    value={username}
                    onChange={({ target }: any) => setUsername(target.value)}
                    error={errors?.email}
                    errorMsg={errors?.email ? errors.email : null}
                    id='login-email'
                  />
                </Box>
                <Box sx={MainStyledBox}>
                  {/* <Typography variant="h2" sx={StyledPasswordText}>
                  Password
                </Typography> */}
                  <HDTextField
                    onClick={() => reactGAEvents('login', 'password')}
                    type="password"
                    lblText="Password"
                    value={password}
                    onChange={({ target }: any) => setPassword(target.value)}
                    error={errors?.password}
                    errorMsg={errors?.password ? errors.password : null}
                    id='login-password'
                  />
                </Box>
                <Box sx={StyledForgetWrapper}>
                  <Box>
                    <Link
                      onClick={() => handleForgetPassword()}
                      sx={StyledForgetPass}
                      tabIndex={0}
                      underline='hover'
                    >
                      Forgot password?
                    </Link>
                  </Box>
                  <Box>
                    <HDButton variant={'contained'} sx={StyledSignIn} endIcon={<ArrowForwardSharpIconStyled />} text={'Sign In'} onClick={(e) => handleSubmit(e)} showAnimation={true} />
                  </Box>
                </Box>
              </Box>
              <Grid container sx={Styledgridwrapper}>
                <Grid item sm={5.5} xs={5.5}>
                  <StyledDivider />
                </Grid>
                <Grid item sm={1} xs={1}>
                  <StyledOr>or</StyledOr>
                </Grid>
                <Grid item sm={5.5} xs={5.5}>
                  <StyledDivider />
                </Grid>
              </Grid>
              <Box>
                <HDButton variant={'contained'} sx={StyledLinkedIn} endIcon={<img alt='LinkedIn logo' src={theme.palette.mode == 'light' ? '../res/LinkedInLight.svg' : '../res/LinkedInDark.svg'} />} text={'Sign in with LinkedIn'} onClick={() => linkedInLogin()} />
              </Box>
              <Box>
                <StyledDivider />
              </Box>
              <Box sx={StyledSignUp}>
                <Box>
                  <Typography variant="h2" sx={TitleStyleHaveAccount}>
                    Don't have an account?
                  </Typography>
                </Box>
                <Box>
                  <Link
                    onClick={() => handleRegister()}
                    sx={StyledSignUpBtn}
                    tabIndex={0}
                    underline='hover'
                  >
                    Sign Up
                  </Link>
                </Box>
              </Box>
              <Box sx={boxWrapper}>
                <Box>
                  <Typography sx={{ ...noteStyle, marginBottom: '0px', marginTop: '0px', fontSize: ResponsiveMobileValue(isMobile, '13px', '14px') }}>Important notice:</Typography>
                </Box>
                <Box sx={btnBox}>
                  <Link
                    onClick={() => {
                      window.open('https://www.heidrick.com/en/privacy/important-notice-employment-recruitment-scams', '_blank', 'noopener,noreferrer');
                    }}
                    sx={btnStyle}
                    tabIndex={0}
                    underline='hover'
                  >
                    Employment and Recruitment Scams
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
          <HDModal
            open={isLoginSessionExists}
            title={'There are existing sessions!'}
            description={'You already have an active session. Do you want to log out of other windows and devices?'}
            handleModal={handleSessionExistsContinue}
            actions={<ModalAction />}
          />
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default SignIn;
