import axios from 'axios';
import { Subject } from 'rxjs';
import eventBus from './Eventbus';
import * as apiTypes from './types';

class Api {
  token: string | null;
  root: string | undefined;
  errors: Subject<unknown>;
  constructor() {
    this.token = sessionStorage.getItem('token');
    this.root = process.env.REACT_APP_API_URL;
    this.errors = new Subject();
  }

  /* UTILITY FUNCTIONS FOR API */

  getAuthOptions() {
    return {
      headers: {
        Authorization: `Token ${this.token}`,
      },
    };
  }

  getAuthOptionsWithoutToken() {
    if (this.token) {
      return {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      };
    }

  }

  getHeader() {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  handleError(error: any) {
    const { response } = error;
    this.errors.next(error);
    if (response?.status === 401) {
      this.logout();
    }
    return error;
  }

  isAuthorized() {
    return this.token !== null;
  }

  createAlert(message: string, severity: string) {
    eventBus.dispatch('createAlert', {
      message: message,
      severity: severity,
    });
  }

  timeoutApiError = (response: any, data: any) => {
    if (response.status === 401) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('oauth_extra_data');
      sessionStorage.removeItem('my-career');
      sessionStorage.removeItem('my-reports');
      this.token = null;
      window.location.href = '/';
    } else {
      return { success: false, data };
    }
  };

  /* AUTH ENDPOINTS */

  // Register with email
  // @payload email: String
  // @payload password: String
  async register(payload: apiTypes.registerTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(`${this.root}/edge/sign-up/`, payload);
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      eventBus.dispatch('toggleLoadingOff');
      return { success: false, data };
    }
  }

  // verify Email
  // @queryparam code: String
  async verifyEmail(code: string) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.get(`${this.root}/edge/auth/signup/verify/?code=${code}`);
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  // Login with email
  async login(payload: apiTypes.loginTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(`${this.root}/edge/auth/login/`, payload, this.getHeader());
      eventBus.dispatch('toggleLoadingOff');
      const { token } = response.data;
      sessionStorage.setItem('token', token);
      this.token = token;
      return { success: true, sessionExists: response?.data?.active_session_exists };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      console.log(error);
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  // exchange oauth token with linkedin
  async tokenExchange(payload: apiTypes.tokenExchangeTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/social/token-exchange/linkedin-oauth2/`,
        payload,
        this.getHeader()
      );
      const { token } = response.data;
      const { oauth_extra_data } = response.data;
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('oauth_extra_data', JSON.stringify(oauth_extra_data));
      this.token = token;
      eventBus.dispatch('toggleLoadingOff');
      return { success: true };
    } catch (error) {
      console.log(error);
      const { response: response_2 } = error;
      const { data } = response_2;
      eventBus.dispatch('toggleLoadingOff');
      return { success: false, data };
    }
  }

  // logout
  async logout() {
    eventBus.dispatch('toggleLoadingOn');
    try {
      await axios.get(`${this.root}/edge/auth/logout/`, this.getAuthOptions());
      eventBus.dispatch('toggleLoadingOff');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('oauth_extra_data');
      sessionStorage.removeItem('my-career');
      sessionStorage.removeItem('my-reports');
      this.token = null;
      window.location.href = '/';
      return { success: true };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('oauth_extra_data');
      sessionStorage.removeItem('my-career');
      sessionStorage.removeItem('my-reports');
      this.token = null;
      window.location.href = '/';
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  /** FORGET PASSWORD ENDPOINTS **/

  // forget password
  // @payload email: String
  async forgetPasswordEmail(payload: apiTypes.forgetPasswordTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(`${this.root}/edge/password/forgot`, payload);
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  // verify code to email for password ready
  // @queryparam code: String
  async verifyPasswordReset(code: string | null) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.get(
        `${this.root}/edge/auth/password/reset/verify/?code=${code}`
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  // reset password
  // @payload code: String
  // @payload password: String
  async resetPassword(payload: apiTypes.resetPasswordTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(`${this.root}/edge/auth/password/reset/verified/`, payload);
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  /** RESET EMAIL OF ACTIVATED ACCOUNT **/

  // update user email
  async updateNewEmail(payload: { email: any }) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/auth/email/change/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Confirmation email has been sent to the new email. A notification email has been sent to the current email.', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in trying to update email', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  // update user email
  async updateEmailPreferences(payload: any, uuid: any) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/unsubscribe-email?uuid=${uuid}`,
        payload,
        this.getAuthOptionsWithoutToken()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  // confirm new email
  async confirmNewEmail(code: string | null) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.get(`${this.root}/edge/auth/email/change/verify/?code=${code}`);
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  /** UPDATE PASSWORD **/

  // @payload email: String
  async updatePassword(payload: { email: any }) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(`${this.root}/edge/auth/password/reset/`, payload);
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('An email has been sent to reset your password', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Something went wrong in trying to update password, no email has been sent.', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  /** Account Endpoint */

  async getAccount() {
    try {
      const response = await axios.get(`${this.root}/edge/profile/account/`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  /****  PROFILE CREATION ENDPOITNS *****/

  // create personal details
  // @payload
  async savePersonalDetail(payload: apiTypes.savePersonalDetailTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/personal-details/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async updatePersonalDetail(payload: apiTypes.updatePersonalDetailTypes, id: never) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.patch(
        `${this.root}/edge/profile/personal-details/${id}/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Successfully updated Personal Details', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in updating Personal Details', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveExperience(payload: apiTypes.saveExperienceTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/experience/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async updateExperience(payload: apiTypes.updateExperienceTypes, id: never) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.patch(
        `${this.root}/edge/profile/experience/${id}/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Successfully updated experience', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in updating experience', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveCareerPreferences(payload: apiTypes.saveCareerPreferencesTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/opportunity-pref/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async updateCareerPreferences(payload: apiTypes.updateCareerPreferencesTypes, id: never) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.patch(
        `${this.root}/edge/profile/opportunity-pref/${id}/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Successfully updated career preferences', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in updating caareer preferences', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveLocationPreferences(payload: apiTypes.saveLocationPreferencesTypes) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/location-pref/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async updateLocationPreferences(payload: apiTypes.updateLocationPreferencesTypes, id: never) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.patch(
        `${this.root}/edge/profile/location-pref/${id}/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Successfully updated location preferences', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in updating location preferences', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveAlignPurposes(payload: { purpose_list: string[] }) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/aligning-purpose/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async updateAlignPurposes(payload: { purpose_list: string[] }, id: never) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.patch(
        `${this.root}/edge/profile/aligning-purpose/${id}/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Successfully updated purposes', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in updating purposes', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveAlignStrength(payload: { strength_list: string[] }) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/aligning-strength/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async updateAlignStrength(payload: { strength_list: string[] }, id: never) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.patch(
        `${this.root}/edge/profile/aligning-strength/${id}/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Successfully updated strength', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in updating strength', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveResume(payload: FormData) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/resume/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      eventBus.dispatch('toggleLoadingOff');
      this.timeoutApiError(response_2, data);
    }
  }
  async saveResumeWithProgress(payload: FormData, onUploadProgress: any) {
    eventBus.dispatch('toggleLoadingOn');
    try {

      const response = await axios.post(
        `${this.root}/edge/profile/resume/`,
        payload,
        {
          headers: {
            Authorization: `Token ${this.token}`,
          },
          onUploadProgress,
        }
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      eventBus.dispatch('toggleLoadingOff');
      this.timeoutApiError(response_2, data);
    }
  }

  async deleteResume(id: any) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.delete(
        `${this.root}/edge/profile/resume/${id}/`,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async downloadResume(id: any) {
    const content_type_mapping: any = {
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/rtf': 'rtf',
      'text/plain': 'txt',
    };

    eventBus.dispatch('toggleLoadingOn');
    let options: any = this.getAuthOptions();
    options['responseType'] = 'blob';
    try {
      const response = await axios.get(`${this.root}/edge/profile/resume/${id}/`, options);
      const url_2 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url_2;
      const contentType = response.headers['content-type'];
      const fileExt = content_type_mapping[contentType];
      const fileName = 'YourResume.' + fileExt;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  /** Change PASSWORD **/

  // @payload old_password: String , new_password: String
  async changePassword(payload: { old_password: string; new_password: string; }) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.put(
        `${this.root}/edge/password/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Your password is changed', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert(error.response.data.message, 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getBusinessFunctions() {
    try {
      const response = await axios
        .get(`${this.root}/edge/business-functions/`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getCapabilityScore(assessmentId: string, roleId: string) {
    try {
      const response = await axios.get(
        `${this.root}/edge/assessments/${assessmentId}/capability-score?rp_id=${roleId}`,
        this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getRoleProfilesById(id: string) {
    try {
      const response = await axios.get(`${this.root}/edge/role-profiles/${id}/`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async postSelectedRole(payload: Object) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(`${this.root}/edge/create-profile`, payload, this.getAuthOptions());
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async generateAssessmentLinks(payload: Object) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(`${this.root}/edge/generate-assessment-links`, payload, this.getAuthOptions());
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getProfile() {
    try {
      const response = await axios.get(`${this.root}/edge/profile`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getAssessmentResult(id: string) {
    try {
      const response = await axios.get(`${this.root}/edge/users/assessment-result/${id}/`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getExperienceReport(rolePId: string, assessmentId: string) {
    try {
      const response = await axios
        .get(`${this.root}/edge/assessments/${assessmentId}/impact-score?rp_id=${rolePId}`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getSkillScoreDetails(skillCardDetails: any) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/drive-factor`, skillCardDetails,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getProfileStatus() {
    try {
      const response = await axios
        .get(`${this.root}/edge/profile/status`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      console.log('error', error)
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveAssesmentStatus(payload: apiTypes.SaveAssesmentStatusType) {
    try {
      const response = await axios
        .post(`${this.root}/edge/assessments/status/`, payload, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getAssesmentStatus() {
    if (this.isAuthorized()) {
      try {
        const response = await axios
          .get(`${this.root}/edge/assessments/status/`, this.getAuthOptions());
        return { success: true, response };
      } catch (error) {
        const { response: response_2 } = error;
        const { data } = response_2;
        this.timeoutApiError(response_2, data);
      }
    }
  }

  async getCoachApi() {
    try {
      const response = await axios
        .get(`${this.root}/edge/coach-match`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getCoachSessionApi() {
    if (this.isAuthorized()) {
      try {
        const response = await axios
          .get(`${this.root}/edge/sessions`, this.getAuthOptions());
        return { success: true, response };
      } catch (error) {
        const { response: response_2 } = error;
        const { data } = response_2;
        this.timeoutApiError(response_2, data);
      }
    }
  }

  async getCoachAvailability(payload: any) {
    try {
      const response = await axios.post(`${this.root}/edge/coach-availability`, payload, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getMyCoachApi() {
    try {
      const response = await axios.get(`${this.root}/edge/coach`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async postEngagement(payload: any) {
    try {
      const response = await axios.post(`${this.root}/edge/engagement`, payload, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getLeaderDevelopmentPlanApi() {
    try {
      const response = await axios
        .get(`${this.root}/edge/development-plan`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async getSubscriptionStatus() {
    try {
      const response = await axios.get(`${this.root}/edge/subscription-details`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async subscribeStripe(payload: any) {
    try {
      const response = await axios.post(`${this.root}/edge/create-checkout-session`, payload, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async scheduleSession(payload: any) {
    try {
      const response = await axios
        .post(`${this.root}/edge/sessions`, payload, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async clearExistingSessions() {
    try {
      const response = await axios
        .post(`${this.root}/edge/log-out-sessions/`, '', this.getAuthOptions());
      const { token } = response.data;
      sessionStorage.setItem('token', token);
      this.token = token;
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async NotifyForFreeSession() {
    try {
      const response = await axios.post(`${this.root}/edge/add-to-waiting-list`, '', this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async generateAssessmentReports(payload: any) {
    try {
      const response = await axios
        .post(`${this.root}/edge/generate-reports`, payload, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async acknowledgeCoachSearch(payload: any) {
    try {
      const response = await axios.post(`${this.root}/edge/acknowledge_coach`, payload, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async exportPDF(reportName: string) {
    try {
      const response = await axios.get(`${this.root}/edge/export-report?report_name=${reportName}`, this.getAuthOptions());
      return { success: true, response };
    } catch (error) {
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async saveCompensation(payload: apiTypes.saveCompensation) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.post(
        `${this.root}/edge/profile/annual-compensation/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

  async updateCompensation(payload: apiTypes.updateCompensation, id: never) {
    eventBus.dispatch('toggleLoadingOn');
    try {
      const response = await axios.patch(
        `${this.root}/edge/profile/annual-compensation/${id}/`,
        payload,
        this.getAuthOptions()
      );
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Successfully updated compensation', 'success');
      return { success: true, response };
    } catch (error) {
      eventBus.dispatch('toggleLoadingOff');
      this.createAlert('Error in updating compensation', 'error');
      const { response: response_2 } = error;
      const { data } = response_2;
      this.timeoutApiError(response_2, data);
    }
  }

}

export default new Api();
